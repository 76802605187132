import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { getCurrentPathname } from '../util';

const Container = styled.div`
  border: 1px solid red;
  padding: 20px;
`;

export const Unauthorized = () => (
  <Container>
    <h3>Unauthorized</h3>
    You must log in to view this.{' '}
    <Link to="/auth" state={{ redirect: getCurrentPathname() }}>
      Log in
    </Link>
  </Container>
);
