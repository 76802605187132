import React from 'react';

interface DisplayErrorProps {
  error: Error;
}

export const DisplayError: React.FC<DisplayErrorProps> = ({ error }) => (
  <p>
    Error :(
    <br />
    {error.name}
    {error.message}
  </p>
);
