import React, { useState, FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import { Icon, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import { DisplayError } from '../components/DisplayError';
import { Unauthorized } from '../components/Unauthorized';
import {
  ChatMessageSearchViewerQueryQuery,
  ChatMessageSearchQueryQuery,
} from '../generated/graphql';
import { exists, formatDate } from '../util';

const chatMessageSearchViewerQuery = gql`
  query chatMessageSearchViewerQuery {
    viewer {
      admin {
        id
        email
      }
    }
  }
`;

const chatMessageSearchQuery = gql`
  query chatMessageSearchQuery($query: String) {
    admin {
      chatMessageSearch(query: $query) {
        results(first: 300) {
          edges {
            node {
              id
              content
              created
              user {
                id
                name
              }
              languageBuddyChat {
                id
                isReported
              }
            }
          }
        }
      }
    }
  }
`;

interface SearchResultsProps {
  query: string;
}

const SearchResults: FC<SearchResultsProps> = ({ query }) => {
  const searchQuery = useQuery<ChatMessageSearchQueryQuery>(
    chatMessageSearchQuery,
    {
      variables: {
        query,
      },
    },
  );
  const { loading, error, data } = searchQuery;

  if (loading) {
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) return <DisplayError error={error} />;

  const results = data?.admin?.chatMessageSearch?.results;

  if (!results) return <p>No messages found :(</p>;

  return (
    <div>
      {results.edges
        ?.filter(exists)
        .map(({ node }) => node)
        .filter(exists)
        .map(msg => (
          <Box marginY={2} key={msg.id}>
            <Link
              to={`/languageBuddyChat/${msg.languageBuddyChat?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Box p={2} component={Paper} position="relative">
                {msg.content}
                <Box
                  display="flex"
                  marginTop={2}
                  fontSize={10}
                  justifyContent="space-between"
                >
                  <span>{msg.user?.name}</span>
                  <span>{formatDate(msg.created)}</span>
                </Box>
                {msg.languageBuddyChat?.isReported && (
                  <Box position="absolute" right={10} top={10}>
                    <Icon>warning</Icon>
                  </Box>
                )}
              </Box>
            </Link>
          </Box>
        ))}
    </div>
  );
};

const ChatMessageSearchPage = () => {
  const viewerQuery = useQuery<ChatMessageSearchViewerQueryQuery>(
    chatMessageSearchViewerQuery,
  );

  const [query, setQuery] = useState<string>('');
  const [rawQuery, setRawQuery] = useState<string>('');

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setQuery(rawQuery);
  };

  const renderInner = () => {
    const { loading, error, data } = viewerQuery;
    if (!data || loading) {
      return (
        <Box p={2}>
          <CircularProgress />
        </Box>
      );
    }
    if (error) return <DisplayError error={error} />;
    if (!data.viewer.admin) return <Unauthorized />;

    return (
      <>
        <h1>Chat message search</h1>
        <p>Search for chat messages</p>

        <form onSubmit={onSubmit}>
          <Box display="flex" bgcolor="#EEE" p={1} alignItems="cener">
            <Box marginRight={2}>
              <TextField
                value={rawQuery}
                onChange={evt => setRawQuery(evt.target.value)}
                label="Query"
              />
            </Box>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Search
            </Button>
          </Box>
        </form>
        <SearchResults query={query} />
      </>
    );
  };

  return (
    <IndexLayout>
      <Page>
        <Container>{renderInner()}</Container>
      </Page>
    </IndexLayout>
  );
};

export default ChatMessageSearchPage;
